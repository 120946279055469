import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { SessionService } from './services/session.service';
import { UtilityService } from './services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'iSkyMarshal Campaign Mgr 17';

  constructor(
    public session: SessionService,
    private utilityService: UtilityService,
  ) {
    this.session.menuLeftVisible = !this.session.isMobileDevice;

    this.session.backendVersion = toSignal(utilityService.getBackendVersion());
    this.session.frontendVersion = this.utilityService.getFrontendVersion();
  };
  
  ngOnInit() {};
}
