import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren, WritableSignal, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ObservableInput, tap } from 'rxjs';

import { CdkAccordionItem } from "@angular/cdk/accordion";
import { Dialog } from '@angular/cdk/dialog';

import { User } from '../../interfaces/user';
import { Campaign } from '../../interfaces/campaign';
import { Race } from '../../interfaces/race';

import { AreYouSure2Component } from '../../shared/are-you-sure2/are-you-sure2.component';

import { CampaignService } from '../../services/campaign.service';
import { RaceService } from '../../services/race.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'user-assignments',
  templateUrl: './user-assignments.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class UserAssignmentsComponent implements AfterViewInit {
  @ViewChildren(CdkAccordionItem) accordions: QueryList<CdkAccordionItem>;

  router = inject(Router);
  user!: User;
  racesForCampaignId: Map<string, Race[]> = new Map();
  campaignIds: string[] = [];
  campaigns: WritableSignal<Campaign[]> = signal([]);

  constructor (
    public session: SessionService,
    private campaignService: CampaignService,
    private raceService: RaceService,
    private dialog: Dialog,
  ) {
    this.user = session.getUser() as User;
    if (this.user == null) {
      throw new Error('No User Assigned!');
    }

    this.campaignService.getCampaigns().subscribe(
      campaigns => this.campaigns = signal(campaigns)
    );

    let controlledRaces$ = this.raceService.getControlledRaces(this.user._id);
    controlledRaces$.subscribe({
      next: (races) => {
        for (const race of races) {
          let races: Race[] = this.racesForCampaignId.get(race.campaignId) || [];
          races.push(race);
          this.racesForCampaignId.set(race.campaignId, races);
        }
      },
      error: (error) => {
        console.error('error in controlledRaces.subscribe', error);
      }
    });

    let raceCampaignIds = this.racesForCampaignId.keys();

    for (const campaignId of raceCampaignIds) {
      if (this.campaignIds.indexOf(campaignId) === -1) {
        let missedCampaign = this.campaignService.getCampaignWithId(campaignId);
        if (missedCampaign) {
          this.campaigns.update(campaigns => {
            campaigns.push(missedCampaign as unknown as Campaign);
            return campaigns;
          });
        }
      }
    }
  };

  ngAfterViewInit (): void {
    this.selectFirstOpenAccordion();
  }

  toggleAction (accordion: CdkAccordionItem, index: number) {
    if (accordion != null) {
      accordion.toggle();
      if (accordion.expanded) {
        this.session.setSelectedCampaignIndex(index);
      }
      else {
        this.scrollIntoViewWithOffset(null, 0);
      }
    }
  };

  selectFirstOpenAccordion () {
    let selectedAccordionIndex: number = this.session.getSelectedCampaignIndex();
    if (selectedAccordionIndex > -1) {
      setTimeout(() => {
        let selectedAccordion = this.accordions.get(selectedAccordionIndex);
        if (selectedAccordion) {
          selectedAccordion.open();
        }
      }, 200);
    }
  };

  scrollIntoViewWithOffset (elementRef: ElementRef, offset: number = 0) {
    let position = 0;
    if (elementRef) {
      position = elementRef.nativeElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset;
    }
    window.scrollTo({
      behavior: 'smooth',
      top: position,
    });
  }

  opened (element: HTMLElement) {
    setTimeout(() => {
      let openedAccordionRef: ElementRef = new ElementRef(element);
      this.scrollIntoViewWithOffset(openedAccordionRef, 122); // TNK - Inital value: 120
    });
  }

  setCampaign (campaign: Campaign) {
    this.session.setCampaign(campaign);
  };

  gotoRace (campaign: Campaign, race: Race) {
    this.session.setCampaignAndRace(campaign, race);
    return this.router.navigate(['/race/starSystemDisplay']);
  };

  // Tim just to get by: AreYouSure2Component
  deleteCampaign (evt: Event, campaign: Campaign): void {
    evt.stopPropagation();
    const dialogRef = this.dialog.open<string>(AreYouSure2Component, {
      width: '65%',
      height: '75%',
      panelClass: 'edit-dialog',
      data: {
        // title: "Warning",
        // message: "This cannot be undone!",
        documentName: "Campaign",
        document: campaign
      },
    });

    dialogRef.closed.subscribe(result => {
      this.session.iAmBusy();
      let campaignId = result['document']['_id'];
      this.campaignService.deleteCampaign(campaignId).pipe(
        tap(() => {
          this.racesForCampaignId.delete(campaignId);
          this.campaigns.update(campaigns => campaigns.filter(c => (c._id !== campaignId)));
        })
      ).subscribe(() => {
        this.session.iAmNotBusy();
      });
    });
  }
}
