import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Encounter, EncounterStatus } from '../interfaces/encounter';
import { Campaign } from '../interfaces/campaign';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EncounterService {
  private http = inject(HttpClient);

  constructor () { }

  getEncounterScales$ (): Observable<string[]> {
    return this.http.get<string[]>('/api/encounter/scales');
  };

  getEncounterStates$ (): Observable<string[]> {
    return this.http.get<string[]>('/api/encounter/states');
  };

  getRaceEncounterStates$ (): Observable<string[]> {
    return this.http.get<string[]>('/api/encounter/raceEncounter/states');
  };

  getEncountersForCampaignId$ (id: string): Observable<Encounter[]> {
    return this.http.get<Encounter[]>('/api/encounter/all/' + id);
  };

  getEncountersForRaceId$ (raceId: string): Observable<Encounter[]> {
    return this.http.get<Encounter[]>('/api/encounter/race/' + raceId);
  };

  getEncounterForId$ (encounterId: string): Observable<Encounter> {
    return this.http.get<Encounter>('/api/encounter/id/' + encounterId);
  };

  getEncounterForRaceEncounterId$ (reid: string): Observable<Encounter> {
    return this.http.get<Encounter>('/api/encounter/reid/' + reid);
  };

  getEncounterStatusForRaceEncounterId$ (reid: string): Observable<EncounterStatus> {
    return this.http.get<EncounterStatus>('/api/encounter/status/reid/' + reid);
  };

  getEncounterStatusForEncounterId$ (eid: string): Observable<EncounterStatus> {
    return this.http.get<EncounterStatus>('/api/encounterStatus/state/eid/' + eid);
  };

  setStatusToSetupForRaceEncounterId$ (reid: string): Observable<EncounterStatus> {
    return this.http.put<EncounterStatus>('/api/encounter/setup/reid/' + reid, null);
  };

  setStatusToReadyForRaceEncounterId$ (reid: string) {
    return this.http.put('/api/encounter/ready/reid/' + reid, null);
  };

  saveEncounter$ (encounter: Encounter): Observable<Encounter> {
    if (encounter._id != null) {
      return this.http.put<Encounter>('/api/encounter/id/' + encounter._id, encounter);
    }
    else {
      return this.http.post<Encounter>('/api/encounter', encounter);
    }
  };

  resetEncounter$ (encounter: Encounter): Observable<Encounter> {
    if (!encounter || encounter._id == null) {
      throw new Error('Invalid Encounter');
    }
    return this.http.put<Encounter>('/api/encounter/' + encounter._id + '/reset', encounter);
  };

  terminateEncounter$ (encounter: Encounter): Observable<Encounter> {
    if (!encounter || encounter._id == null) {
      throw new Error('Invalid Encounter');
    }
    return this.http.put<Encounter>('/api/encounter/' + encounter._id + '/terminate', null);
  };

  deleteEncounter$ (encounter: Encounter) {
    if (!encounter || encounter._id == null) {
      throw new Error('Invalid Encounter');
    }
    return this.http.delete('/api/encounter/' + encounter._id);
  };
};
