import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { FormsModule } from '@angular/forms';
import { SharedModule } from "../shared/shared.module";

import { RaceRoutingModule } from './race-routing.module';
import { LeftRaceMenuComponent } from './left-race-menu/left-race-menu.component';
import { RaceBaseComponent } from './race-base/race-base.component';
import { RaceDisplayComponent } from './race-display/race-display.component';
import { RaceEncounterComponent } from './race-encounter/race-encounter.component';
import { RaceHomeComponent } from './race-home/race-home.component';


@NgModule({
  declarations: [
    LeftRaceMenuComponent,
    RaceBaseComponent,
    RaceDisplayComponent,
    RaceEncounterComponent,
    RaceHomeComponent,
  ],
  imports: [
    CommonModule,
    CdkAccordionModule,
    CdkListboxModule,
    FormsModule,
    RaceRoutingModule,
    SharedModule,
  ],
  exports: [
  ]
})
export class RaceModule { }
