import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreditsComponent } from './credits/credits.component';
import { UserForgotPasswordComponent } from './user/user-forgot-password/user-forgot-password.component';
import { TestingComponent } from "./testing/testing.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/user/assignments',
    pathMatch: 'full'
  },
  { path: 'credits', component: CreditsComponent },
  { path: 'forgotPassword', component: UserForgotPasswordComponent },
  {
    path: 'testing', component: TestingComponent,
    loadChildren: () => import('./testing/testing.module').then((m) => m.TestingModule),
  },

];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { scrollPositionRestoration: 'top' }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
