import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticated, authorizedMaster } from '../auth/auth.guard';

import { UserAccountComponent } from './user-account/user-account.component';
import { UserAssignmentsComponent } from './user-assignments/user-assignments.component';
import { UserForgotPasswordComponent } from './user-forgot-password/user-forgot-password.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/user/assignments',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: UserLoginComponent
    },
    {
        path: 'register',
        component: UserRegisterComponent
    },
    {
        path: 'forgotPassword',
        component: UserForgotPasswordComponent
    },
    {
        path: 'user',
        canActivate: [authenticated],
        children: [
            {
                path: '',
                redirectTo: '/user/assignments',
                pathMatch: 'full'
            },
            {
                path: 'assignments',
                component: UserAssignmentsComponent,
                canActivate: [authenticated],
            },
            {
                path: 'account',
                component: UserAccountComponent,
                canActivate: [authenticated],
            },
            {
                path: 'resetPassword',
                component: UserResetPasswordComponent,
                canActivate: [authenticated],
            },
            {
                path: 'user-list',
                component: UserListComponent,
                canActivate: [authorizedMaster],
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserRoutingModule { }
