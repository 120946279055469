<div>
  <div class="w-full flex" #theTop>
    <div class="pb-8 w-1/5 pl-4 bottom-0 flex flex-col fixed top-14 md:w-[26%] lg:w-[20%] text-1.25 navbarLeft8 overflow-y-scroll scrollbar-none ">
      <!-- All campaign table names here -->
      @for(table of tables; track $index) {
      <div id="_{{table.title}}_" class="px-4 w-full flex flex-col justify-end" [scrollToTop]="theTop"
        (click)="displayTable(table)">
        <a class="no-underline hover:text-yellow-300 hover:no-underline">
          {{table.title}}
        </a>
      </div>
      }
    </div>

    <div class="px-2 pb-8 border-0 flex flex-col fixed top-14 md:left-[26%] lg:left-[20%] right-0 bottom-0 ism-white scrollbar-none overflow-scroll">
      <div id="_CAMPAIGN-TABLES-HEADER_" class="w-full flex justify-between">
        <div class="flex items-center justify-start text-1.5">
          {{campaign.name}} - Campaign Table: {{selectedTable.title}}
        </div>
        <div class="flex items-center justify-end">
          <a class="m-2 p-2 btn" type="button" (click)="saveTableData(selectedTable)">Save </a>
          <!-- @if(table.custom) { -->
          <a class="m-2 p-2 btn" type="button" (click)="deleteTableData(selectedTable)">Reset</a>
          <!-- } -->
          <a class="m-2 p-2 btn" type="button" (click)="revertChanges(selectedTable)">Cancel</a>
        </div>
      </div>

      <!-- the ng-template is not rendered until used by *ngTemplateOutlet -->
      <!-- <ng-template #actions>
        <div class="mx-2 px-2 w-full flex items-center justify-end">
          <a class="m-2 p-2 btn" type="button" (click)="saveTableData(selectedTable)">Save </a>
          <a class="m-2 p-2 btn" type="button" (click)="deleteTableData(selectedTable)">Reset</a>
          <a class="m-2 p-2 btn" type="button" (click)="revertChanges(selectedTable)">Cancel</a>
        </div>
      </ng-template>
      <ng-container *ngTemplateOutlet="actions"></ng-container> -->

      <!-- Tim's original theme ag-theme-balham' -->
      <ag-grid-angular style="width: 95%; height: 500px;" class="ag-theme-balham-dark"
        [rowData]="data()" [columnDefs]="columnDefs">
      </ag-grid-angular>

      <!-- <ng-container *ngTemplateOutlet="actions"></ng-container> -->
    </div>
  </div>
</div>
