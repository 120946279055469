<div class="mx-6 py-1 relative text-center text-white flex flex-grow flex-col flex-wrap md:w-4/5 scrollbar-none">
  <div id="_User-Assignment_TOP" class="ism-gold p-2 w-full sticky z-30 top-[59px] border border-b-0 border-white text-1.875 font-bold iBTTiny:text-center flex flex-col justify-center lanPixel2xl:flex-row tracking-wider lanPixel2xl:text-left bg-black bg-opacity-75">
    <div class="ism-white">Campaigns with &nbsp;</div>
    <div class="ism-white">Assigned Races</div>
  </div>

  <cdk-accordion class="text-yellow-300 border border-yellow-300 flex flex-col box-border scrollbar-none">
    <!--  -->
    @for (campaign of campaigns() | orderBy: "name"; track $index) {
      <cdk-accordion-item id="_Campaigns_" class="text-yellow-300 border border-yellow-300 data-even-light" #thisAccordionItem #accordionItem="cdkAccordionItem" role="button" tabindex="0" [attr.id]="'accordion-header-' + $index" [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + $index" (opened)="opened(thisAccordionItem)">
        <div id="_Campaign-Name_" class="w-full font-bold border border-yellow-300 flex items-center justify-between iBTTiny:flex-col lanPixel2xl:flex-row lanPixel2xl:justify-between" (click)="toggleAction(accordionItem, $index)">
          <div class="w-full flex items-center justify-between iBTTiny:flex-col lanPixel2xl:flex-row">
            <div class="mx-2 pt-2 lanPixel2xl:pt-0 inline-flex justify-center whitespace-nowrap text-1.25 md:text-1.5" data-tooltip="Campaign Name">
              {{ campaign.name }}
            </div>

            <div id="_Icon-Button-Set_" class="w-full flex items-center justify-center lanPixel2xl:justify-end">
              <!-- new ng-grid campaign editor -->
              <!-- <ism-svg id="_Campaign-Properties_" [routerLink]="['/campaign', campaign._id, 'properties']" class="mx-1 px-1" [name]="'squareStack-3d'" routerLinkAcive="ngActiveLinkWhite" data-tooltip="Campaign Properties" (click)="setCampaign(campaign)"></ism-svg> -->
              <ism-svg id="_Campaign-Settings" [routerLink]="['/campaign', campaign._id, 'edit']" class="mx-1 px-1 tooltip-tr" [name]="'gear'" routerLinkAcive="ngActiveLinkWhite" data-tooltip="Campaign Settings" (click)="setCampaign(campaign)"></ism-svg>
              <!-- temporary text-based campaign editor -->
              <ism-svg id="_Campaign-Tables_" [routerLink]="['/campaign', campaign._id, 'tables']" class="mx-1 px-1 tooltip-tr" [name]="'squareStack-3d'" routerLinkAcive="ngActiveLinkWhite" data-tooltip="Campaign Tables" (click)="setCampaign(campaign)"></ism-svg>
              <ism-svg id="_Campaing-Processes_" [routerLink]="['/campaign', campaign._id, 'management']" class="mx-1 px-1 tooltip-tr" [name]="'arrowsCircular'" routerLinkActive="ngActiveLinkWhite" data-tooltip="Campaign Processes" (click)="setCampaign(campaign)"></ism-svg>
              <ism-svg id="_Campaign-Display_" [routerLink]="['/campaign', campaign._id, 'display']" class="mx-1 px-1 tooltip-tl" [name]="'sparkles'" routerLinkActive="ngActiveLinkWhite" data-tooltip="Star System and Race Display" (click)="setCampaign(campaign)"></ism-svg>
              <ism-svg id="_Encounter-Manager_" [routerLink]="['/campaign', campaign._id, 'encounters']" class="mx-1 px-1 tooltip-tl" [name]="'saturnPurple'" routerLinkActive="ngActiveLinkWhite" data-tooltip="Encounter Management" (click)="setCampaign(campaign)"></ism-svg>
              <ism-svg id="_Delete-Campaign_" class="mx-1 px-1 tooltip-tl" [name]="'trashcan'" data-tooltip="Delete Campaign" (click)="deleteCampaign($event, campaign)"></ism-svg>
            </div>
          </div>
          <div class="pt-1 items-center hidden whitespace-nowrap text-1.25 text-white">
            @if (session.isMobileDevice) {
              <span class="lg:hidden">Tap&nbsp;</span>
            }
            @if (!session.isMobileDevice) {
              <span class="hidden lanPixel2xl:block">Click&nbsp;</span>
            }
            to {{ accordionItem.expanded ? "Close" : "Open" }}
          </div>
        </div>

        <div id="_Races_" class="px-2" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + $index" [attr.aria-labelledby]="'accordion-header-' + $index">
          <ul>
            @for (race of racesForCampaignId.get(campaign._id); track $index) {
              <li class="ml-2 text-1.25 text-left text-white" (click)="gotoRace(campaign, race)">
                {{ race.name }}
              </li>
            }
          </ul>
        </div>
      </cdk-accordion-item>
    }
    <!--  -->
    @empty {
      <div class="py-2">
        <div class="text-1.5 text-center ism-gold">No race assignments at this time</div>
        <div class="text-1.25 text-center italic font-light text-white">Contact your SkyMarshal</div>
      </div>
    }
  </cdk-accordion>
  <div class="h-6"></div>
</div>
