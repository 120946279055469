import { Component } from '@angular/core';

@Component({
  selector: 'user-account',
  templateUrl: './user-account.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class UserAccountComponent {
  myPageName: string = "user-account";
}
