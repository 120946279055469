<div id="_race-display-CENTER_" class="w-full flex flex-col iBTTiny:w-11/12 md:w-4/5 iBTTiny:mx-auto md:m-0">
  <div class="w-full flex">
    Race Display (and Edit)
  </div>
  <table>
    <tr>
      <th>Name</th>
      <td>{{race.name}}</td>
    </tr>
    <tr>
      <th>Player Race</th>
      <td>
        <input type="checkbox" class="form-checkbox" [checked]="race.playerRace">
      </td>
    </tr>
    <tr>
      <th>Activated</th>
      <td>
        <input type="checkbox" class="form-checkbox" [checked]="race.activated">
      </td>
    </tr>
    <tr>
      <th>Turn Created</th>
      <td>{{race.turnCreated}}</td>
    </tr>
    <tr>
      <th>Turn Activated</th>
      <td>{{race.turnActivated}}</td>
    </tr>
    <tr>
      <th>Creation Date</th>
      <td>{{race.creationDate | date}}</td>
    </tr>
    <tr>
      <th>System Minimum</th>
      <td>{{race.systemMinimum}}</td>
    </tr>
    <tr>
      <th>System Maximum</th>
      <td>{{race.systemMaximum}}</td>
    </tr>
    <tr>
      <th>Racial Militarism</th>
      <td>{{race.racialMilitarism}}</td>
    </tr>
    <tr>
      <th>Racial Determination</th>
      <td>{{race.racialDetermination}}</td>
    </tr>
    <tr>
      <th>Racial Chauvinism</th>
      <td>{{race.racialChauvinism}}</td>
    </tr>
    <tr>
      <th>Success Militarism</th>
      <td>{{race.successMilitarism}}</td>
    </tr>
    <tr>
      <th>Success Determination</th>
      <td>{{race.successDetermination}}</td>
    </tr>
    <tr>
      <th>Success Chauvinism</th>
      <td>{{race.successChauvinism}}</td>
    </tr>
    <tr>
      <th>Government</th>
      <td>{{race.governmentId}} - {{government()?.name}}</td>
    </tr>
    <tr>
      <th>Tech Level</th>
      <td>{{race.techLevel}}</td>
    </tr>
    <tr>
      <th>Initial PU</th>
      <td>{{race.pu}}</td>
    </tr>
    <tr>
      <th>Initial PTU</th>
      <td>{{race.ptu}}</td>
    </tr>
    <tr>
      <th>Free PCF</th>
      <td>{{race.freePcf}}</td>
    </tr>
    <tr>
      <th>Free PTU</th>
      <td>{{race.freePtu}}</td>
    </tr>
    <tr>
      <th>Maximum Ships with 'X'</th>
      <td>{{race.maxX}}</td>
    </tr>
    <tr>
      <th>Ready For Economics Turn</th>
      <td>
        <input type="checkbox" class="form-checkbox" [checked]="race.readyForEconomicsTurn">
      </td>
    </tr>
    <tr>
      <th>Economics Complete Turn</th>
      <td>{{race.economicsCompleteTurn}}</td>
    </tr>
    <tr>
      <th>Ready For Fleet Orders Turn</th>
      <td>
        <input type="checkbox" class="form-checkbox" [checked]="race.readyForFleetOrdersTurn">
      </td>
    </tr>
    <tr>
      <th>Home Star System</th>
      <td>{{starSystem()?.number}}
      </td>
    </tr>
    <tr>
      <th>Home Planet</th>
      <td>{{(homePlanet$ | async)?.locator}}
      </td>
    </tr>
    <tr>
      <!-- remove this tr when multi-select is working -->
      <td colspan="2">{{race.playerIds}}</td>
    </tr>
    <tr>
      <th>
        Assigned Players<br>
        Selected users have property 'aria-selected' set to true<br>
        For view, it needs to be disabled - not working as documented<br>
        https://material.angular.io/cdk/listbox/examples
      </th>
      <td class="text-white">
        <ul cdkListbox cdkListboxMultiple aria-disabled="true" [(ngModel)]="race.playerIds" class="example-listbox">
          @for (user of users; track user) {
          <li [cdkOption]="user._id" class="text-white">{{user._id}} - {{user.first}} {{user.last}}</li>
          }
        </ul>
      </td>
    </tr>
  </table>
</div>
