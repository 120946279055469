import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticated } from '../auth/auth.guard';

import { CommunicationComponent } from './communication/communication.component';
import { FirstContactComponent } from './first-contact/first-contact.component';
import { PoliticalNegotiationComponent } from './political-negotiation/political-negotiation.component';

const routes: Routes = [
  {
    path: 'stateFirstContact',
    canActivate: [authenticated],
    component: FirstContactComponent
  },
  {
    path: 'stateCommunication',
    canActivate: [authenticated],
    component: CommunicationComponent
  },
  {
    path: 'statePoliticalNegotiation',
    canActivate: [authenticated],
    component: PoliticalNegotiationComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StateDepartmentRoutingModule { }
