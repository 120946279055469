import { Component, Signal, WritableSignal, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Dialog } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { tap } from 'rxjs';

import { AreYouSure2Component } from '../../shared/are-you-sure2/are-you-sure2.component';
import { OverrideSelectionComponent } from '../override-selection/override-selection.component';

import { CampaignService } from '../../services/campaign.service';
import { SessionService } from '../../services/session.service';
import { StarSystemService } from '../../services/star-system.service';

import { Campaign } from '../../interfaces/campaign';
import { DialogData } from '../../interfaces/dialog-data';
import { Race } from '../../interfaces/race';
import { RaceService } from '../../services/race.service';
import { StarSystem } from '../../interfaces/star-system';

@Component({
  selector: 'campaign-display',
  templateUrl: './campaign-display.component.html',
})
export class CampaignDisplayComponent {
  route = inject(ActivatedRoute);
  session = inject(SessionService);
  campaignService = inject(CampaignService);
  starSystemService = inject(StarSystemService);
  raceService = inject(RaceService);
  router = inject(Router);
  dialog = inject(Dialog);

  myPageName: string = "campaign-display";
  campaignId = signal<string>("");
  campaign = signal<Campaign | undefined>(undefined);
  starSystems = signal<StarSystem[] | undefined>([]);
  starSystemLookup: { [key: string]: StarSystem; } = {};
  races = signal<Race[] | undefined>([]);

  constructor () {
    this.campaignId = signal(this.route.snapshot.paramMap.get('campaignId') as string);
    let sessionCampaign = this.session.getCampaign();

    if (this.campaignId() === sessionCampaign?._id) {
      this.campaign = signal(sessionCampaign);
    }
    else {
      this.campaignService.getCampaignWithId(this.campaignId()).subscribe(
        campaign => {
          this.campaign.set(campaign);
          this.session.setCampaign(this.campaign());
        }
      );
    }

    this.starSystemService.getAllStarSystemsForCampaign(this.campaignId()).subscribe(
      allStarSystems => {
        this.starSystems.set(allStarSystems);
        for (let starSystem of allStarSystems) {
          this.starSystemLookup[starSystem._id] = starSystem;
        }
      }
    );

    this.raceService.getAllRacesForCampaign(this.campaignId()).subscribe(
      races => this.races.set(races)
    );
  };

  openStarSystem (starSystem: StarSystem) {
    return this.router.navigate(['/campaign/' + this.campaignId() + '/starSystemDisplay/' + starSystem._id]);
  };

  newStarSystem (overrides: { [key: string]: any; } = {}) {
    this.session.iAmBusy();
    this.starSystemService.newStarSystem(this.campaign(), undefined, undefined, overrides).subscribe(
      newStarSystem => {
        this.starSystems.update(allStarSystems => allStarSystems.concat(newStarSystem));
        this.starSystemLookup[newStarSystem._id] = newStarSystem;

        this.raceService.getRacesForStarSystemId(newStarSystem._id).subscribe(
          races => {
            if (races && races.length > 0) {
              this.races.update(knownRaces => knownRaces.concat(races));
              this.raceService.processRaces(this.races(), 'racesForCampaign' + newStarSystem.campaignId);
            }
          }
        );

        this.session.iAmNotBusy();
        return this.openStarSystem(newStarSystem);
      }
    );
  };

  overrideStarSystem () {
    let overrides = Object.assign({}, this.session['overrides']);
    const dialogRef = this.dialog.open<DialogData>(OverrideSelectionComponent, {
      // width: '65%',
      // height: '75%',
      panelClass: 'edit-dialog',
      data: {
        documentName: "Guidance",
        document: overrides,
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session['overrides'] = result['document'];
        this.newStarSystem(result['document']);
        console.log(result);
      }
    });
  };

  openRace (race: Race) {
    this.session.setRace(race);
    return this.router.navigate(['/race/starSystemDisplay']);
  };

  deleteRace (race: Race) {
    const dialogRef = this.dialog.open<string>(AreYouSure2Component, {
      // width: '65%',
      // height: '75%',
      panelClass: 'edit-dialog',
      data: {
        documentName: "Race",
        document: race
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        let race = result['document'];
        this.raceService.deleteRace(race).subscribe(() => {
          this.races.update(races => races.filter(r => r._id !== race._id));
          this.session.setNotifyMessage('Race Deleted');
          this.session.iAmNotBusy();
        });
      }
    });
  };
};
