import { Component, ElementRef, Injector, OnInit, QueryList, Signal, ViewChildren, WritableSignal, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Campaign } from '../../interfaces/campaign';
import { Encounter } from '../../interfaces/encounter';
import { Race } from '../../interfaces/race';
import { StarSystem } from '../../interfaces/star-system';

import { authorizedMarshal } from '../../auth/auth.guard';

import { CampaignService } from '../../services/campaign.service';
import { EncounterService } from '../../services/encounter.service';
import { RaceService } from '../../services/race.service';
import { SessionService } from '../../services/session.service';
import { StarSystemService } from '../../services/star-system.service';
import { Dialog } from '@angular/cdk/dialog';
import { DialogData } from '../../interfaces/dialog-data';
import { EncounterEditComponent } from '../encounter-edit/encounter-edit.component';
import { AreYouSure2Component } from '../../shared/are-you-sure2/are-you-sure2.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'encounter-manager',
  templateUrl: './encounter-manager.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})

export class EncounterManagerComponent implements OnInit {
  @ViewChildren(CdkAccordionItem) accordions: QueryList<CdkAccordionItem>;

  private encounterService = inject(EncounterService);
  private route = inject(ActivatedRoute);
  private campaignService = inject(CampaignService);
  private router = inject(Router);
  public session = inject(SessionService);
  private userService = inject(UserService);
  private raceService = inject(RaceService);
  private starSystemService = inject(StarSystemService);
  private dialog = inject(Dialog);

  injector = inject(Injector);
  authorizedMarshal: boolean = authorizedMarshal();
  encounters: WritableSignal<Encounter[]> = signal([]);
  encounterToEdit: Encounter = null;
  campaignId: string = "";
  campaign: Signal<Campaign>;
  allRaces: Signal<Race[]>;
  hashRaces: { [key: string]: Race; } = {};
  starSystems: Signal<StarSystem[]>;
  hashStarSystems: { [key: string]: StarSystem; } = {};
  scales: Signal<string[]>;
  states: Signal<string[]>;
  reStates: Signal<string[]>;

  constructor () {
    this.session.iAmBusy();

    this.route.params.subscribe((params: Params) => {
      this.campaignId = params['campaignId'];
    });

    this.campaign = signal<Campaign>(this.session.getCampaign());
    this.scales = toSignal(this.encounterService.getEncounterScales$());
    this.states = toSignal(this.encounterService.getEncounterStates$());
    this.reStates = toSignal(this.encounterService.getRaceEncounterStates$());
  };

  ngOnInit (): void {
    this.encounterService.getEncountersForCampaignId$(this.campaignId).subscribe(
      encounters => this.encounters = signal<Encounter[]>(encounters)
    );

    this.allRaces = toSignal<Race[]>(
      this.raceService.getAllRacesForCampaign(this.campaignId),
      { injector: this.injector }
    );

    this.starSystems = toSignal<StarSystem[]>(
      this.starSystemService.getAllStarSystemsForCampaign(this.campaignId),
      { injector: this.injector }
    );

    effect(() => {
      if (!this.allRaces() || !this.starSystems() || !this.scales() || !this.states() || !this.reStates()) {
        return;
      }

      this.hashRaces = this.allRaces().reduce((hash, race) => (hash[race._id] = race, hash), {});
      this.hashStarSystems = this.starSystems().reduce((hash, starSystem) => (hash[starSystem._id] = starSystem, hash), {});
      setTimeout(() => this.session.iAmNotBusy());
    }, { injector: this.injector }
    );
  };

  private hasChildEncounter (encounter: Encounter) {
    return encounter.numberOfChildEncounters > 0;
  };

  updateSavedEncounter (encounter: Encounter) {
    let found = false;
    this.encounters.update(e => e.map(
      nextEncounter => {
        if (nextEncounter._id === encounter._id) {
          found = true;
          nextEncounter = encounter;
        }
        return nextEncounter;
      }
    ));
    if (!found) {
      this.encounters.update(f => ([...f, encounter]));
    }
    this.session.setNotifyMessage("Encounter saved");
    this.session.iAmNotBusy();
  };

  processEncounter (encounter: Encounter) {
    this.encounterService.saveEncounter$(encounter).subscribe(
      savedEncounter => this.updateSavedEncounter(savedEncounter)
    );
  };

  openEncounterEditDialog (encounter: Encounter) {
    const dialogRef = this.dialog.open<DialogData>(EncounterEditComponent, {
      width: '65%',
      height: '75%',
      panelClass: 'edit-dialog',
      data: {
        documentName: "Encounter",
        document: encounter,
        starSystems: this.starSystems(),
        scales: this.scales(),
        states: this.states(),
        reStates: this.reStates(),
        races: this.allRaces(),
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        this.processEncounter(result.document as Encounter);
      }
    });
  };

  createEncounter () {
    let campaign = this.session.getCampaign();
    this.encounterToEdit = {
      campaignId: campaign._id,
      turnStrategic: campaign.turn || 1,
      starSystemId: null,
      systemHex: '0101',
      interceptionHex: '0101',
      scale: 'System',
      status: 'New',
      encounterTurn: 1,
      pulseStrategic: 1,
      turnSystem: 1,
      pulseSystem: 1,
      miniPulse: 0,
    };
    this.openEncounterEditDialog(this.encounterToEdit);
  };

  deleteEncounter (event: Event, encounter: Encounter) {
    if (event) {
      event.stopPropagation();
    }
    if (this.hasChildEncounter(encounter)) {
      this.session.setAlertMessage('Cannot delete encounter with child encounters');
      return;
    }
    const dialogRef = this.dialog.open<string>(AreYouSure2Component, {
      width: '65%',
      height: '75%',
      panelClass: 'edit-dialog',
      data: {
        // title: "Warning",
        // message: "This cannot be undone!",
        documentName: "Encounter",
        document: encounter
      },
    });

    dialogRef.closed.subscribe(result => {
      if (result) {
        this.session.iAmBusy();
        this.encounterService.deleteEncounter$(encounter).subscribe(
          () => {
            this.encounters.update(encounters => encounters.filter(
              e => (e._id !== encounter._id)
            ));
            this.session.setNotifyMessage('Ship Class Deleted');
            this.session.iAmNotBusy();
          }
        );
      }
    });
  };

  renewEncounter (event: Event, encounter: Encounter) {
    if (event) {
      event.stopPropagation();
    }
    if (this.hasChildEncounter(encounter)) {
      this.session.setAlertMessage('Cannot renew encounter with child encounters');
      return;
    }
    this.session.iAmBusy();
    this.encounterService.resetEncounter$(encounter).subscribe(
      savedEncounter => this.updateSavedEncounter(savedEncounter)
    );
  };

  testEncounter (event: Event, encounter: Encounter) {
    if (event) {
      event.stopPropagation();
    }
    this.session.setAlertMessage('This feature is not yet implemented');
  };

  editEncounter (event: Event, encounter: Encounter) {
    if (event) {
      event.stopPropagation();
    }
    if (this.hasChildEncounter(encounter)) {
      this.session.setAlertMessage('Cannot edit encounter with child encounters');
      return;
    }
    this.encounterToEdit = Object.assign({}, encounter);
    this.openEncounterEditDialog(this.encounterToEdit);
  };

  scrollIntoViewWithOffset (elementRef: ElementRef, offset: number = 0) {
    let position = 0;
    if (elementRef) {
      position = elementRef.nativeElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset;
    }
    window.scrollTo({
      behavior: 'smooth',
      top: position,
    });
  };

  opened (element: HTMLElement) {
    setTimeout(() => {
      let openedAccordionRef: ElementRef = new ElementRef(element);
      this.scrollIntoViewWithOffset(openedAccordionRef, 64);
    });
  };
};
