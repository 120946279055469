import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { authorizedMaster } from '../auth/auth.guard';

import { Campaign } from '../interfaces/campaign';

@Injectable({
  providedIn: 'root'
})

export class CampaignService {
  constructor (
    private http: HttpClient,
  ) { }

  private getAllCampaigns () {
    return this.http.get<Campaign[]>('/api/campaign/all');
  };

  private getAuthorizedCampaigns () {
    return this.http.get<Campaign[]>('/api/campaign/skyMarshal');
  };

  getCampaigns () {
    if (authorizedMaster()) {
      return this.getAllCampaigns();
    }
    return this.getAuthorizedCampaigns();
  }

  getCampaignWithId (id: string) {
    return this.http.get<Campaign>('/api/campaign/id/' + id);
  };

  createCampaign (campaign: Campaign): Observable<Campaign> {
    return this.http.post<Campaign>('/api/campaign', campaign);
  };

  updateCampaign (campaign: Campaign): Observable<Campaign> {
    return this.http.put<Campaign>('/api/campaign/' + campaign._id, campaign);
  };

  saveCampaign (campaign: Campaign): Observable<Campaign> {
    let campaign$: Observable<Campaign>;
    if (campaign._id == null) {
      campaign$ = this.createCampaign(campaign);
    }
    else {
      campaign$ = this.updateCampaign(campaign);
    }
    return campaign$;
  };

  deleteCampaign (id: string) {
    return this.http.delete('/api/campaign/' + id);
  };
};
