import { Component } from '@angular/core';

//FIXME[epic=tnk-Make Work,seq=8] user-list / auth guard
//ANCHOR - See problems tab
import { authorizedMaster } from '../../auth/auth.guard';
import { User } from '../../interfaces/user';

import { SessionService } from '../../services/session.service';
import { UserService } from "../../services/user.service";

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
})
export class UserListComponent {
  myPageName: string = "user-list";
  user!: User;

  constructor(
      public session: SessionService,
  ) {

      // this.user = session.getUser();
      // if (this.user == null) {
      //     throw new Error('No User Assigned!');
      // }
  }

}
