import { Component, Input } from '@angular/core';

// import * as CampaignData from "../../../assets/data/campaignCtrl.json";

import { SessionService } from '../../services/session.service';

@Component({
  selector: 'campaign-properties',
  templateUrl: './campaign-properties.component.html',
})
export class CampaignPropertiesComponent {
  @Input() campaignId: string = "";
  myPageName: string = "campaign-properties";

  public cc: any;

  constructor(
      public session: SessionService,
  ) {
      console.log('cc: ', this.cc);
  };

  ngOnInit(): void {
      this.session.activeTab = 1;
      this.session.activeProperty = 0;
  }

}
