import { AfterViewInit, Component, ElementRef, Injector, QueryList, Renderer2, ViewChild, ViewChildren, effect, inject } from '@angular/core';
import { CdkAccordionItem } from '@angular/cdk/accordion';

import { SessionService } from '../../services/session.service';

@Component({
    selector: 'left-race-menu',
    templateUrl: './left-race-menu.component.html',
})
export class LeftRaceMenuComponent implements AfterViewInit {
    injector = inject(Injector);
    myPageName: string = "left-race-menu";

    @ViewChild('accordionItem0') raceAccordion!: CdkAccordionItem;
    @ViewChild('accordionItem1') startupAccordion!: CdkAccordionItem;
    @ViewChild('accordionItem2') economicAccordion!: CdkAccordionItem;
    @ViewChildren(CdkAccordionItem, { read: ElementRef }) accordionElements!: QueryList<ElementRef>;
    linkToFollow?: ElementRef;
    selectedIndex: number = 0;

    constructor (
        public session: SessionService,
        private menu: ElementRef,
        private renderer: Renderer2
    ) { };

    ngAfterViewInit () {
        setTimeout(() => {
            this.raceAccordion.open();
            effect(() => {
                if (this.session.isFirstTurn() == null) {
                    return;
                }

                // console.log('selectedIndex / isFirstTurn', this.selectedIndex, this.session.isFirstTurn());
                if (this.selectedIndex === 2 && this.session.isFirstTurn()) {
                    this.startupAccordion.open();
                }
                else if (this.selectedIndex === 1 && !this.session.isFirstTurn()) {
                    // open regular economic accordion
                    this.economicAccordion.open();
                }
            }, { injector: this.injector });
        });
    };

    opened (index: number) {
        setTimeout(() => {
            this.selectedIndex = index;
            let openedAccordion = this.accordionElements.get(index) as ElementRef;
            let newScrollTop = openedAccordion.nativeElement.offsetTop;
            this.renderer.setProperty(this.menu.nativeElement, "scrollTop", newScrollTop);

            let firstLink = openedAccordion.nativeElement.querySelector('div.menuLeftItem');
            if (firstLink) {
                this.linkToFollow = new ElementRef(firstLink);
            }
        });
    };

}
