import { NgModule } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { FormsModule } from "@angular/forms";
import { QuickHtmlModule } from "../quick-html/quick-html.module";
import { RouterModule } from '@angular/router';
import { SharedModule } from "../shared/shared.module";

import { AgGridModule } from 'ag-grid-angular';

import { CampaignDisplayComponent } from "./campaign-display/campaign-display.component";
import { CampaignEditComponent } from './campaign-edit/campaign-edit.component';
import { CampaignManagerComponent } from './campaign-manager/campaign-manager.component';
import { CampaignPropertiesComponent } from './campaign-properties/campaign-properties.component';
import { CampaignRoutingModule } from './campaign-routing.module';
import { EncounterManagerComponent } from "./encounter-manager/encounter-manager.component";
import { EncounterResolutionComponent } from './encounter-resolution/encounter-resolution.component';
import { CampaignTablesComponent } from './campaign-tables/campaign-tables.component';
import { EncounterEditComponent } from './encounter-edit/encounter-edit.component';
import { StarSystemDisplayComponent } from './star-system-display/star-system-display.component';
import { OverrideSelectionComponent } from './override-selection/override-selection.component';


@NgModule({
  imports: [
    AgGridModule,
    CampaignRoutingModule,
    CdkAccordionModule,
    CommonModule,
    DialogModule,
    FormsModule,
    QuickHtmlModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    CampaignDisplayComponent,
    CampaignEditComponent,
    CampaignPropertiesComponent,
    CampaignManagerComponent,
    CampaignTablesComponent,
    EncounterEditComponent,
    EncounterManagerComponent,
    EncounterResolutionComponent,
    StarSystemDisplayComponent,
    OverrideSelectionComponent,
  ],
  exports: [
  ]
})
export class CampaignModule { }
