import { Component, inject } from '@angular/core';
import { Router } from "@angular/router";

import { Campaign } from "../../interfaces/campaign";
import { Race } from "../../interfaces/race";

import { SessionService } from "../../services/session.service";

@Component({
  selector: 'race-base',
  templateUrl: './race-base.component.html',
  host: {
    'class': 'flex flex-grow'
  }
})
export class RaceBaseComponent {
  myPageName: string = "race-base";
  router = inject(Router);
  campaign?: Campaign = undefined;
  race?: Race = undefined;

  constructor(public session: SessionService) {
    this.campaign = this.session.getCampaign();
    this.race = this.session.getRace();
    if (this.campaign == null || this.race == null) {
      this.router.navigate(['/user/assignments']);
    }
  }

}
