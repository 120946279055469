<!-- full pagge with modal -->
<div class="mx-auto relative bg-white flex flex-col h-fit w-7/8 md:w-5/12 lg:w-3/8 xl:w-1/3 top-16 bg-opacity-80 rounded-xl">
  <div id="_close-btn_" class="p-4 z-30 relative flex items-center justify-end bg-opacity-80 rounded-xl" routerLink="/login">
    <ism-svg [name]="'xRound'" [classString]="'w-10 h-10 text-accent-blue-dk-500'"></ism-svg>
  </div>
  <div class="px-6 lanPixel2xl:px-8 relative top-[-3rem] flex flex-col">
    <div id="_header_" class="my-4 text-center flex flex-col justify-center">
      <ism-marquee></ism-marquee>
      <div class="text-center italic font-bold ism-blue">Forgot Password</div>
      <div class="text-center uppercase font-bold ism-blue">Security Department</div>
      <div class="text-center text-gray-800 text-1.125 uppercase">Replacment Security Credentials</div>
    </div>
    <div>
      <div id="_username_">
        <div class="uppercase leading-6 block text-1 ism-blue">Identification Required</div>
        <div class="my-1 py-1 w-full flex">
          <div class="w-full border border-gray-800 flex">
            <div id="_ism-svg_envelope_" class="p-2 w-1/5 border-r border-gray-800 bg-gray-100 flex rounded-tl-lg rounded-bl-lg items-center justify-center H-14">
              <ism-svg [name]="'user'" [classString]="'w-10 h-10 text-gray-800 flex items-center hover:text-yellow-300'"></ism-svg>
            </div>
            <div id="_inputs_" class="w-full flex items-center">
              <input id="_firstName_" type="text" size="25" required placeholder="First Name" [(ngModel)]="first" class="mx-2 px-2 w-full h-14 border-0 bg-white bg-opacity-10 text-1.25 text-black placeholder-gray-800" />
              <input id="_lastName_" type="text" size="25" required placeholder="Last Name" [(ngModel)]="last" class="mx-2 px-2 w-full h-14 rounded-br-lg rounded-tr-lg border-0 bg-white bg-opacity-10 text-1.25 text-black placeholder-gray-800" />
            </div>
          </div>
        </div>
      </div>

      <div id="_email_">
        <label for="email" class="mx-2 mt-2 pt-2 text-left uppercase leading-6 block text-1 ism-blue">Email Address</label>
        <div class="my-1 py-1 w-full flex">
          <div class="w-full border border-gray-800 flex">
            <div class="p-2 w-1/5 border-r border-gray-800 bg-gray-100 flex rounded-tl-lg rounded-bl-lg items-center justify-center h-14 bg-opacity-40">
              <ism-svg [name]="'envelope'" [classString]="'w-10 h-10 text-gray-800'"></ism-svg>
            </div>
            <input name="email" id="email" type="email" size="70" required autocomplete="email" placeholder="emailAddress@webmail.com" [(ngModel)]="email" name="email" class="h-14 w-full rounded-br-lg rounded-tr-lg border-0 bg-white bg-opacity-10 p-4 text-1.25 text-black placeholder-gray-800" />
          </div>
        </div>
      </div>

      <div id="_submit-btn_" class="my-2 py-2">
        <button type="submit" class="btn text-1.25 flex w-full justify-center rounded-md px-3 py-3 leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2" (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </div>
</div>
