import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authenticated } from '../auth/auth.guard';

import { RaceBaseComponent } from './race-base/race-base.component';
import { RaceDisplayComponent } from './race-display/race-display.component';
import { RaceEncounterComponent } from './race-encounter/race-encounter.component';
import { RaceHomeComponent } from './race-home/race-home.component';

const routes: Routes = [
  {
    path: 'race',
    component: RaceBaseComponent,
    canActivate: [authenticated],
    children: [
      {
        path: '',
        redirectTo: '/race/starSystemDisplay',
        pathMatch: 'full',
      },
      {
        path: 'starSystemDisplay',
        component: RaceHomeComponent,
      },
      {
        path: 'raceEncounters',
        component: RaceEncounterComponent
      },
      {
        path: 'raceDisplay',
        component: RaceDisplayComponent,
      },
      {
        path: 'economics',
        loadChildren: () => import('../economics/economics.module').then(m => m.EconomicsModule)
      },
      {
        path: 'messages',
        loadChildren: () => import('../messages/messages.module').then(m => m.MessagesModule)
      },
      {
        path: 'admiralty',
        loadChildren: () => import('../admiralty/admiralty.module').then(m => m.AdmiraltyModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RaceRoutingModule { }
