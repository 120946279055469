import { Component, Signal, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { CdkListbox, CdkOption } from '@angular/cdk/listbox';

import { SessionService } from '../../services/session.service';
import { StarSystemService } from '../../services/star-system.service';
import { TableService } from '../../services/table.service';

import { Race } from '../../interfaces/race';
import { StarSystem, Star, Planet } from '../../interfaces/star-system';
import { Government } from '../../interfaces/government';
import { User } from '../../interfaces/user';

@Component({
  selector: 'race-display',
  templateUrl: './race-display.component.html',
})
export class RaceDisplayComponent {
  myPageName: string = "race-display";
  race: any;
  government: Signal<Government | undefined>;
  starSystem: Signal<StarSystem | undefined>;
  star$ = new Subject<Star>();
  homePlanet$ = new Subject<Planet>();
  users: User[] | undefined;
  isDisabled: boolean = true;

  constructor(
    public session: SessionService,
    private starSystemService: StarSystemService,
    private tableService: TableService,
  ) {
    this.users = this.session.getUsers();
    this.race = this.session.getRace() as Race;
    this.starSystem = toSignal<StarSystem>(starSystemService.getStarSystem(this.race.starSystemId));
    this.government = toSignal(tableService.getGovernmentById(this.race.governmentId, this.race.campaignId));
    effect(() => {
      let starSystemInternal = this.starSystem();
      if (!starSystemInternal) {
        return
      }
      else {
        for (const star of starSystemInternal.stars) {
          for (const planet of star.planets) {
            if (planet._id === this.race.homePlanetId) {
              this.star$.next(star);
              this.homePlanet$.next(planet);
            }
          }
        }
      }
    });
  }
}
