import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StateDepartmentRoutingModule } from './state-department-routing.module';
import { FirstContactComponent } from './first-contact/first-contact.component';
import { CommunicationComponent } from './communication/communication.component';
import { PoliticalNegotiationComponent } from './political-negotiation/political-negotiation.component';


@NgModule({
  declarations: [
    FirstContactComponent,
    CommunicationComponent,
    PoliticalNegotiationComponent
  ],
  imports: [
    CommonModule,
    StateDepartmentRoutingModule
  ]
})
export class StateDepartmentModule { }
