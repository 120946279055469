<div id="_race-home-CENTER_" class="w-full flex-col flex-wrap box-border">
  <div class="w-full flex flex-grow">
    <div id="_SYS-ID" data-text="Star System ID" class="flex items-center whitespace-nowrap">
      <!-- -->
      <label for="issPicker" class="whitespace-nowrap text-1.25 mr-2">Select ID:</label>
      <select id="issPicker" class="form-select whitespace-nowrap bg-black text-left text-1.25 text-black ism-black mr-2 w-40" style="width: 120px" [ngModel]="starSystemId" (ngModelChange)="processSelectedStarSystemId($event)">
        <!-- name="selectedIss$" ngModel"> -->
        <option class="ism-black" *ngFor="let iss of informationStarSystems() | orderBy: 'starSystemNumber'" [ngValue]="iss.starSystemId">{{ iss.starSystemNumber }}</option>
      </select>
    </div>

    @if (selectedIss$ | async; as selectedIss) {
      <div id="_BUTTON-SET" class="items-center iBTTiny:hidden md:flex">
        <!-- data-text="Opens in another tab" -->
        <a target="_blank" type="button" class="btn mx-2 whitespace-nowrap px-2 text-center text-1.25" [href]="'/ibm.html?sid=' + selectedIss?.starSystemId + '&rid=' + race!._id">View</a>

        <!-- data-text="Opens in another tab"  -->
        <a target="_blank" type="button" class="btn mx-2 whitespace-nowrap px-2 text-center text-1.25" [href]="'/iwm.html?rid=' + race._id + '&center=' + selectedIss?.starSystemNumber">Warp Map</a>
      </div>
    }

    <div id="_CHECKBOX-SHOW-MOONS" data-text="Toggles Moon Display" class="w-full text-yellow-300 flex items-center iBTTiny:justify-end lanPixel2xl:justify-start">
      <input id="showMoons" type="checkbox" class="form-checkbox" (change)="updateShowMoons()" />
      <div class="text-middle whitespace-nowrap px-2 text-1.25">Show Moons</div>
    </div>
  </div>

  <star-system-display [informationStarSystem]="selectedIss$ | async" [displayMoons]="showMoons" (starSystemId)="processSelectedStarSystemId($event)"></star-system-display>

  @if (fleets() | fleetFilter: starSystemId | orderBy: "fleetName"; as localFleetList) {
    <div class="py-4 font-bold text-1.75 ism-gold w-full flex">
      <div>Fleets</div>
      <span id="_CHECKBOX-SHOW-ZERO_SHIP_FLEETS" data-text="Toggles Show Empty Fleets" class="px-2 pl-64 w-full text-yellow-300 flex items-center">
        <input id="hideEmptyFleets" type="checkbox" class="form-checkbox" (change)="updateShowEmptyFleets()" />
        <div class="text-middle whitespace-nowrap px-2 text-1.25">Show Empty Fleets</div>
      </span>
    </div>

    @if (race.techLevel < 0) {
      <div>This is not a high-tech race</div>
    }
    <!--  -->
    @else {
      @if (localFleetList | fleetFilter: undefined : "active" | orderBy: "fleetName"; as fleetList) {
        <div class="font-bold text-1.25 ism-gold">Active Fleets</div>
        @for (fleet of fleetList; track $index) {
          <div class="text-white whitespace-nowrap text-1.25" [ngClass]="fleet.shipIds?.length == 0 && hideEmptyFleets ? 'hidden' : ''">{{ fleet.fleetName }} at system hex {{ fleet.locationHex }} ({{ fleet.shipIds?.length }} ships)</div>
        }
      }

      @if (localFleetList | fleetFilter: undefined : "static" | orderBy: "fleetName"; as fleetListStatic) {
        <div class="pt-2 font-bold text-1.25 ism-gold">Static</div>
        @for (fleet of fleetListStatic; track $index) {
          <div class="text-white whitespace-nowrap text-1.25" [ngClass]="fleet.shipIds?.length == 0 && hideEmptyFleets ? 'hidden' : ''">{{ fleet.fleetName }} at system hex {{ fleet.locationHex }} ({{ fleet.shipIds?.length }} ships)</div>
        }
      }
    }
  }
</div>
<div class="h-6"></div>
