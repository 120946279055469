<div class="w-full h-full flex flex-col box-border">
  <header id="_app-header_" class="w-full right-0 left-0 z-50 fixed flex flex-col box-border">
    @if (session.user) {
      <navbar-top class="w-full flex h-[58px]"></navbar-top>
    }
    @if (session.ismTesting) {
      <testing-ism></testing-ism>
    }
    <banner-error></banner-error>
    <banner-alert></banner-alert>
    <banner-notify></banner-notify>
  </header>

  <whirly-spinner class="full-page-overlay" [visibility]="session.busy"></whirly-spinner>

  <div id="_app-center_" class="w-full relative top-[59px] flex flex-grow flex-wrap border-transparent border-spacing-0 scrollbar-none box-border">
    <router-outlet></router-outlet>
    <div class="h-10"></div>
  </div>

  <Footer id="_app-footer_" class="bottom-0 left-0 z-20 fixed">
    <div class="px-2 py-0.5 w-fit overlay-dark-70 versions versions-border">
      server: {{ session.backendVersion() }}
      @if (session.isMobileDevice) {
        <br />
      } @else {
        /
      }
      webapp: {{ session.frontendVersion }}
    </div>
  </Footer>
</div>
